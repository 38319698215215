.box{
    background-color: rgb(245, 246, 246);
    position: fixed;
    top: 0;
    z-index: 99999;
}

.box .sign{
    background-color: white;
}

.box .box-btn{
   display: flex;
   justify-content: center;
   font-size: 30px;
   margin-top: 30px;
}

.box .box-btn button{
    padding: 10px 0;
    border-radius: 30px;
    width: 250px;
 }

.box .box-btn button:last-child{
    color: white;
    background-color: red;

 }
 .box .box-btn button:first-child{
    margin-right: 50px;
    color: rgb(51, 50, 50);
    background-color: rgb(211, 210, 210);
 }

.box .title{
    text-align: center;
    color: black;
    font-size: 40px;
    line-height: 150px;
}