.introduce {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .introduce .div {
    background-color: var(--cbgwhite);
    border: 1px none;
   
    position: relative;
    width: 780px;
    top:-176px;
  }
  
  .introduce .overlap-group-wrapper {
    height: 88px;
    left: 0;
    position: absolute;
    top: 88px;
    width: 784px;
  }
  
  .introduce .overlap-group {
    background-color: #ffffff;
    height: 88px;
    position: relative;
    width: 780px;
  }
  
  .introduce .text-wrapper {
    color: var(--ctextwbgprimary-90);
    
    font-size: var(--font-16-24-medium-font-size);
    font-style: var(--font-16-24-medium-font-style);
    font-weight: var(--font-16-24-medium-font-weight);
    left: 184px;
    letter-spacing: var(--font-16-24-medium-letter-spacing);
    line-height: var(--font-16-24-medium-line-height);
    position: absolute;
    text-align: center;
    top: 20px;
    white-space: nowrap;
  }
  
  .introduce .view {
    height: 56px;
    left: 692px;
    position: absolute;
    top: 16px;
    width: 56px;
  }
  
  .introduce .div-2 {
    background-color: #191919;
    border-radius: 3px;
    height: 6px;
    left: 24px;
    position: absolute;
    top: 24px;
    width: 6px;
  }
  
  .introduce .div-3 {
    background-color: #191919;
    border-radius: 3px;
    height: 6px;
    left: 12px;
    position: absolute;
    top: 24px;
    width: 6px;
  }
  
  .introduce .div-4 {
    background-color: #191919;
    border-radius: 3px;
    height: 6px;
    left: 40px;
    position: absolute;
    top: 24px;
    width: 6px;
  }
  
  .introduce .iphone-x-status-bars {
    background-color: #ffffff;
    height: 88px;
    left: 0;
    position: absolute;
    top: 0;
    width: 780px;
  }
  
  .introduce .battery {
    height: 24px;
    left: 694px;
    position: absolute;
    top: 36px;
    width: 48px;
  }
  
  .introduce .wifi {
    height: 24px;
    left: 650px;
    position: absolute;
    top: 36px;
    width: 32px;
  }
  
  .introduce .cellular-connection {
    height: 22px;
    left: 604px;
    position: absolute;
    top: 38px;
    width: 32px;
  }
  
  .introduce .time-style {
    height: 36px;
    left: 42px;
    overflow: hidden;
    position: absolute;
    top: 30px;
    width: 108px;
  }
  
  .introduce .time {
    color: #000000;
    
    font-size: 32px;
    font-weight: 400;
    left: 20px;
    letter-spacing: -1.2px;
    line-height: normal;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .introduce .group {
    height: 68px;
    left: 0;
    position: absolute;
    top: 2378px;
    width: 780px;
  }
  
  .introduce .home-indicator-light {
    height: 68px;
    overflow: hidden;
  }
  
  .introduce .home-indicator-wrapper {
    background-color: #ffffff;
    height: 72px;
    left: -2px;
    position: relative;
    top: -2px;
    width: 780px;
  }
  
  .introduce .home-indicator {
    background-color: #000000;
    border-radius: 5px;
    height: 14px;
    left: 252px;
    position: relative;
    top: 42px;
    width: 280px;
  }
  
  .introduce .overlap-wrapper {
    height: 98px;
    left: 0;
    position: absolute;
    top: 2280px;
    width: 800px;
  }
  
  .introduce .overlap {
    background-color: #ffffff;
    height: 102px;
    left: -2px;
    position: relative;
    top: -2px;
    width: 784px;
  }
  
  .introduce .overlap-2 {
    height: 88px;
    left: 508px;
    position: absolute;
    top: 6px;
    width: 82px;
  }
  
  .introduce .view-2 {
    height: 56px;
    left: 14px;
    position: absolute;
    top: 0;
    width: 56px;
  }
  
  .introduce .overlap-group-2 {
    height: 36px;
    left: 10px;
    position: relative;
    top: 12px;
    width: 40px;
  }
  
  .introduce .rectangle {
    height: 4px;
    left: 0;
    position: absolute;
    top: 32px;
    width: 40px;
  }
  
  .introduce .img {
    height: 36px;
    left: 2px;
    position: absolute;
    top: 0;
    width: 26px;
  }
  
  .introduce .text-wrapper-2 {
    color: #000000e6;
    
    font-size: 20px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 36px;
    opacity: 0.9;
    position: absolute;
    text-align: center;
    top: 52px;
    white-space: nowrap;
  }
  
  .introduce .overlap-3 {
    height: 88px;
    left: 350px;
    position: absolute;
    top: 6px;
    width: 82px;
  }
  
  .introduce .overlap-4 {
    height: 88px;
    left: 38px;
    position: absolute;
    top: 6px;
    width: 82px;
  }
  
  .introduce .overlap-5 {
    height: 88px;
    left: 194px;
    position: absolute;
    top: 6px;
    width: 82px;
  }
  
  .introduce .overlap-6 {
    height: 36px;
    left: 8px;
    position: relative;
    top: 10px;
    width: 40px;
  }
  
  .introduce .rectangle-2 {
    height: 32px;
    left: 2px;
    position: absolute;
    top: 0;
    width: 36px;
  }
  
  .introduce .vector {
    height: 4px;
    left: 10px;
    position: absolute;
    top: 8px;
    width: 20px;
  }
  
  .introduce .vector-2 {
    height: 4px;
    left: 0;
    position: absolute;
    top: 0;
    width: 40px;
  }
  
  .introduce .vector-3 {
    height: 8px;
    left: 18px;
    position: absolute;
    top: 28px;
    width: 4px;
  }
  
  .introduce .vector-4 {
    height: 4px;
    left: 10px;
    position: absolute;
    top: 16px;
    width: 8px;
  }
  
  .introduce .overlap-7 {
    height: 88px;
    left: 664px;
    position: absolute;
    top: 6px;
    width: 82px;
  }
  
  .introduce .img-2 {
    height: 32px;
    left: 8px;
    position: absolute;
    top: 12px;
    width: 40px;
  }
  
  .introduce .mask-group {
    height: 780px;
    left: 0;
    position: absolute;
    top: 176px;
    width: 780px;
  }
  
  .introduce .text-wrapper-3 {
    color: var(--ctextwbgprimary-90);
    
    font-size: var(--font-18-26-medium-font-size);
    font-style: var(--font-18-26-medium-font-style);
    font-weight: var(--font-18-26-medium-font-weight);
    left: 40px;
    letter-spacing: var(--font-18-26-medium-letter-spacing);
    line-height: var(--font-18-26-medium-line-height);
    position: absolute;
    top: 1018px;
    width: 700px;
  }
  
  .introduce .text-wrapper-4 {
    color: var(--ctextwbgprimary-90);
    
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    left: 40px;
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    position: absolute;
    top: 1118px;
    width: 700px;
  }
  
  .introduce .text-wrapper-5 {
    color: var(--ctextwbgnormal-60);
    
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    left: 40px;
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    position: absolute;
    text-align: justify;
    top: 1186px;
    width: 700px;
    padding-bottom: 300px;
    font-size: 27px

  }
  .introduce .ming{
    color: var(--ctextwbgnormal-60);
    
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    position: absolute;
   
    right: 0px;
  }
  .introduce .ming2{
    color: var(--ctextwbgnormal-60);
    
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    position: absolute;
    
    right: 0px;
  }
  .introduce .logo{
    position: absolute;
    top: 280px;
    width: 200px;
    left: 280px;
  }