:root {
  --16-24-regular-font-family: "LiciumFont2022-Regular", Helvetica;
  --16-24-regular-font-size: 32px;
  --16-24-regular-font-style: normal;
  --16-24-regular-font-weight: 400;
  --16-24-regular-letter-spacing: 0px;
  --16-24-regular-line-height: 48px;
  --c-000000: rgba(0, 0, 0, 1);
  --cbglightgray: rgba(250, 250, 250, 1);
  --cbgwhite: rgba(255, 255, 255, 1);
  --cmaskbg: rgba(0, 0, 0, 0.4);
  --ctextbbgprimary-100: rgba(255, 255, 255, 1);
  --ctextthemegreen: rgba(5, 117, 104, 1);
  --ctextwbgnormal-60: rgba(0, 0, 0, 0.6);
  --ctextwbgplaceholder-20: rgba(0, 0, 0, 0.2);
  --ctextwbgprimary-90: rgba(0, 0, 0, 0.9);
  --ctextwbgsecondary-40: rgba(0, 0, 0, 0.4);
  --font-12-20-regular-font-family: "LiciumFont2022-Regular", Helvetica;
  --font-12-20-regular-font-size: 24px;
  --font-12-20-regular-font-style: normal;
  --font-12-20-regular-font-weight: 400;
  --font-12-20-regular-letter-spacing: 0px;
  --font-12-20-regular-line-height: 40px;
  --font-14-22-regular-font-family: "LiciumFont2022-Regular", Helvetica;
  --font-14-22-regular-font-size: 28px;
  --font-14-22-regular-font-style: normal;
  --font-14-22-regular-font-weight: 400;
  --font-14-22-regular-letter-spacing: 0px;
  --font-14-22-regular-line-height: 44px;
  --font-16-24-medium-font-family: "LiciumFont2022-Medium", Helvetica;
  --font-16-24-medium-font-size: 32px;
  --font-16-24-medium-font-style: normal;
  --font-16-24-medium-font-weight: 500;
  --font-16-24-medium-letter-spacing: 0px;
  --font-16-24-medium-line-height: 48px;
  --font-16-24-regular-font-family: "LiciumFont2022-Regular", Helvetica;
  --font-16-24-regular-font-size: 32px;
  --font-16-24-regular-font-style: normal;
  --font-16-24-regular-font-weight: 400;
  --font-16-24-regular-letter-spacing: 0px;
  --font-16-24-regular-line-height: 48px;
  --font-20-28-medium-font-family: "LiciumFont2022-Medium", Helvetica;
  --font-20-28-medium-font-size: 40px;
  --font-20-28-medium-font-style: normal;
  --font-20-28-medium-font-weight: 500;
  --font-20-28-medium-letter-spacing: 0px;
  --font-20-28-medium-line-height: 56px;
  --font-18-26-medium-font-family: "LiciumFont2022-Medium", Helvetica;
  --font-18-26-medium-font-size: 36px;
  --font-18-26-medium-font-style: normal;
  --font-18-26-medium-font-weight: 500;
  --font-18-26-medium-letter-spacing: 0px;
  --font-18-26-medium-line-height: 52px;
}

input {
  border: none;
  color: #333 !important;
  padding: 0px 5px;
  outline: none;
}


input ::placeholder{
  color: rgba(0, 0, 0, 0.2) !important;
}

button{
  border: none;
  background-color: transparent;
}


#body {
  /* ios系统字体被手动调大优化 */
  /* -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important; */
}

@font-face {
  font-family: "LiciumFont2022-Regular";
  src: url("../public/ziti/LiciumFont2022-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "LiciumFont2022-Medium";
  src: url("../public/ziti/LiciumFont2022-Medium.ttf") format("truetype");
}
 /* .hehe{
  background-image: url(../../../public/img/2024bg.jpg);
 } */
body{
  font-family: 'LiciumFont2022-Regular' !important;
}

.zhong{
  font-family: 'LiciumFont2022-Medium' !important;
}

