.moment {
    background-color: #f8f8f8;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .moment .div {
    background-color: var(--cbglightgray);
    border: 2px none;
    height: 1512px;
    overflow: hidden;
    position: relative;
    width: 780px;
    top:-176px;
  }
  
  .moment .overlap-group-wrapper {
    height: 44px;
    left: 0;
    position: absolute;
    top: 44px;
    width: 392px;
  }
  
  .moment .overlap-group {
    background-color: #ffffff;
    height: 44px;
    position: relative;
    width: 390px;
  }
  
  .moment .icon-fanhui {
    height: 28px !important;
    left: 16px !important;
    position: absolute !important;
    top: 8px !important;
    width: 28px !important;
  }
  
  .moment .text-wrapper {
    color: var(--ctextwbgprimary-90);
    
    font-size: var(--font-16-24-medium-font-size);
    font-style: var(--font-16-24-medium-font-style);
    font-weight: var(--font-16-24-medium-font-weight);
    left: 92px;
    letter-spacing: var(--font-16-24-medium-letter-spacing);
    line-height: var(--font-16-24-medium-line-height);
    position: absolute;
    text-align: center;
    top: 10px;
    white-space: nowrap;
  }
  
  .moment .view {
    height: 28px;
    left: 346px;
    position: absolute;
    top: 8px;
    width: 28px;
  }
  
  .moment .div-2 {
    background-color: #191919;
    border-radius: 1.5px;
    height: 3px;
    left: 12px;
    position: absolute;
    top: 12px;
    width: 3px;
  }
  
  .moment .div-3 {
    background-color: #191919;
    border-radius: 1.5px;
    height: 3px;
    left: 6px;
    position: absolute;
    top: 12px;
    width: 3px;
  }
  
  .moment .div-4 {
    background-color: #191919;
    border-radius: 1.5px;
    height: 3px;
    left: 20px;
    position: absolute;
    top: 12px;
    width: 3px;
  }
  
  .moment .iphone-x-status-bars {
    background-color: #ffffff;
    height: 44px;
    left: 0;
    position: absolute;
    top: 0;
    width: 390px;
  }
  
  .moment .battery {
    height: 12px;
    left: 347px;
    position: absolute;
    top: 18px;
    width: 24px;
  }
  
  .moment .wifi {
    height: 12px;
    left: 325px;
    position: absolute;
    top: 18px;
    width: 16px;
  }
  
  .moment .cellular-connection {
    height: 11px;
    left: 302px;
    position: absolute;
    top: 19px;
    width: 16px;
  }
  
  .moment .time-style {
    height: 18px;
    left: 21px;
    overflow: hidden;
    position: absolute;
    top: 15px;
    width: 54px;
  }
  
  .moment .time {
    color: #000000;
    
    font-size: 16px;
    font-weight: 400;
    left: 10px;
    letter-spacing: -0.6px;
    line-height: normal;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .moment .group {
    height: 68px;
    left: 0;
    position: absolute;
    top: 1620px;
    width: 780px;
  }
  
  .moment .home-indicator-light {
    background-color: #ffffff;
    height: 68px;
  }
  
  .moment .home-indicator {
    background-color: #000000;
    border-radius: 5px;
    height: 14px;
    left: 250px;
    position: relative;
    top: 40px;
    width: 280px;
  }
  
  .moment .text-wrapper-2 {
    color: var(--ctextwbgsecondary-40);
    
    font-size: var(--font-16-24-regular-font-size);
    font-style: var(--font-16-24-regular-font-style);
    font-weight: var(--font-16-24-regular-font-weight);
    /* left: 228px; */
    letter-spacing: var(--font-16-24-regular-letter-spacing);
    line-height: var(--font-16-24-regular-line-height);
    position: absolute;
    text-align: center;
    top: 838px;
    white-space: nowrap;
    text-align: center;
    width: 100%;
  }
  
  .moment .img {
    height: 300px;
    left: 240px;
    position: absolute;
    top: 500px;
    width: 300px;
  }
  
  .moment .overlap-wrapper {
    height: 49px;
    left: 0;
    position: absolute;
    top: 761px;
    width: 400px;
  }
  
  .moment .overlap {
    background-color: #ffffff;
    height: 51px;
    left: -1px;
    position: relative;
    top: -1px;
    width: 392px;
  }
  
  .moment .overlap-2 {
    height: 44px;
    left: 254px;
    position: absolute;
    top: 3px;
    width: 41px;
  }
  
  .moment .view-2 {
    height: 28px;
    left: 7px;
    position: absolute;
    top: 0;
    width: 28px;
  }
  
  .moment .overlap-group-2 {
    height: 18px;
    left: 5px;
    position: relative;
    top: 6px;
    width: 20px;
  }
  
  .moment .rectangle {
    height: 2px;
    left: 0;
    position: absolute;
    top: 16px;
    width: 20px;
  }
  
  .moment .group-2 {
    height: 18px;
    left: 1px;
    position: absolute;
    top: 0;
    width: 18px;
  }
  
  .moment .text-wrapper-3 {
    color: #000000e6;
    
    font-size: 10px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 18px;
    opacity: 0.9;
    position: absolute;
    text-align: center;
    top: 26px;
    white-space: nowrap;
  }
  
  .moment .overlap-3 {
    height: 44px;
    left: 175px;
    position: absolute;
    top: 3px;
    width: 41px;
  }
  
  .moment .overlap-4 {
    height: 44px;
    left: 19px;
    position: absolute;
    top: 3px;
    width: 41px;
  }
  
  .moment .overlap-5 {
    height: 44px;
    left: 97px;
    position: absolute;
    top: 3px;
    width: 41px;
  }
  
  .moment .overlap-6 {
    height: 18px;
    left: 4px;
    position: relative;
    top: 5px;
    width: 20px;
  }
  
  .moment .rectangle-2 {
    height: 16px;
    left: 1px;
    position: absolute;
    top: 0;
    width: 18px;
  }
  
  .moment .vector {
    height: 2px;
    left: 5px;
    position: absolute;
    top: 4px;
    width: 10px;
  }
  
  .moment .vector-2 {
    height: 2px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;
  }
  
  .moment .vector-3 {
    height: 4px;
    left: 9px;
    position: absolute;
    top: 14px;
    width: 2px;
  }
  
  .moment .vector-4 {
    height: 2px;
    left: 5px;
    position: absolute;
    top: 8px;
    width: 4px;
  }
  
  .moment .overlap-7 {
    height: 44px;
    left: 332px;
    position: absolute;
    top: 3px;
    width: 41px;
  }
  
  .moment .img-2 {
    height: 16px;
    left: 4px;
    position: absolute;
    top: 6px;
    width: 20px;
  }
  
  .moment .img-3 {
    height: 1223px;
    left: -5319px;
    position: absolute;
    top: 2928px;
    width: 390px;
  }
  
  .moment .img-4 {
    height: 844px;
    left: -4448px;
    position: absolute;
    top: 2928px;
    width: 390px;
  }
  
  .moment .img-5 {
    height: 844px;
    left: -4014px;
    position: absolute;
    top: 2928px;
    width: 390px;
  }
  
  .moment .img-6 {
    height: 2118px;
    left: -4885px;
    position: absolute;
    top: 2928px;
    width: 390px;
  }
  