.see {
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  font-family: 'LiciumFont2022-Regular' !important;
  color: #f2f2f2;

}

body{
  
  background-size: cover; /* 背景图片覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图片不重复 */
  background-position: bottom; /* 背景图片居中 */
  background-attachment: fixed;
  width: 100%; /* 元素宽度 */
}


.see .overlap-wrapper {
  /* background-color: var(--cbglightgray); */
  border: 1px none;
  
  width: 780px;
}
.see .overlap {
  /* background-color: #f9f9f9; */
  border-radius: 16px;
  position: relative;
  width: 700px;
}



.see .element-l {
  height: 1688px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 780px;
}

.see .iphone-x-status-bars {
  height: 88px;
  left: 0;
  position: absolute;
  top: 0;
  width: 780px;
}

.see .battery {
  height: 24px;
  left: 694px;
  position: absolute;
  top: 36px;
  width: 48px;
}

.see .wifi {
  height: 24px;
  left: 650px;
  position: absolute;
  top: 36px;
  width: 32px;
}

.see .cellular-connection {
  height: 22px;
  left: 604px;
  position: absolute;
  top: 38px;
  width: 32px;
}

.see .time-style {
  height: 36px;
  left: 42px;
  overflow: hidden;
  position: absolute;
  top: 30px;
  width: 108px;
}

.see .time {
  color: #ffffff;

  font-size: 32px;
  font-weight: 400;
  left: 20px;
  letter-spacing: -1.2px;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.see .text-wrapper {
  color: var(--ctextbbgprimary-100);

  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 50px;
  position: absolute;
  text-align: center;
  top: 320px;
  width: 780px;
}

.see .group {
  background-image: url(../../../public/img/img2/logo.png);
  background-size: 100% 100%;
  height: 40px;
  left: 290px;
  position: absolute;
  top: 256px;
  width: 198px;
}

.see .overlap-group-wrapper {

  left: 90px;
  position: absolute;
  width: 690px;
}

.see .overlap-group {
  background-color: #ffffff;
    border-radius: 50px;
    height: 100px;
    width: 600px;
}

.see .div {
  height: 324px;
  left: 0;
  position: absolute;
  top: 0;
  width: 700px;
}

.see .view {
  height: 108px;
  left: 0;
  position: absolute;
  top: 216px;
  width: 712px;
}

.see .overlap-group-2 {
  height: 108px;
  position: relative;
  width: 700px;
}

.see .image {
  height: 108px;
  left: 0;
  position: absolute;
  top: 0;
  width: 700px;
}

.see .text-wrapper-2 {
  color: var(--ctextwbgprimary-90);

  font-size: var(--font-14-22-regular-font-size);
  font-style: var(--font-14-22-regular-font-style);
  font-weight: var(--font-14-22-regular-font-weight);
  left: 40px;
  letter-spacing: var(--font-14-22-regular-letter-spacing);
  line-height: var(--font-14-22-regular-line-height);
  position: absolute;
  top: 29px;
  white-space: nowrap;
}

.see .text-wrapper-3 {
  color: var(--ctextwbgplaceholder-20);

  font-size: var(--font-14-22-regular-font-size);
  font-style: var(--font-14-22-regular-font-style);
  font-weight: var(--font-14-22-regular-font-weight);
  left: 230px;
  letter-spacing: var(--font-14-22-regular-letter-spacing);
  line-height: var(--font-14-22-regular-line-height);
  position: absolute;
  top: 29px;
  white-space: nowrap;
  width: 320px;
}

.see .text-wrapper-3::placeholder {
  color: #bdbcbc;
}

.see .text-wrapper-4 {
  color: #5A7CAB;
  font-size: var(--font-14-22-regular-font-size);
  font-style: var(--font-14-22-regular-font-style);
  font-weight: var(--font-14-22-regular-font-weight);
  left: 508px;
  letter-spacing: var(--font-14-22-regular-letter-spacing);
  line-height: var(--font-14-22-regular-line-height);
  position: absolute;
  text-align: right;
  top: 32px;
  white-space: nowrap;
  width: 155px;

}

.see .img {
  height: 2px;
  left: 40px;
  object-fit: cover;
  position: absolute;
  top: 108px;
  width: 660px;
}

.see .group-2 {
  height: 216px;
  left: 0;
  position: absolute;
  top: 0;
  width: 700px;
}

.see .overlap-2 {
  height: 108px;
  left: 0;
  position: absolute;
  top: 108px;
  width: 708px;
}

.see .div-wrapper {
  height: 108px;
  left: 0;
  position: absolute;
  top: 0;
  width: 708px;
}

.see .overlap-group-3 {
  background-image: url(../../../public/img/login/overlap-group-3.svg);
  background-size: 100% 100%;
  height: 108px;
  position: relative;
  width: 700px;
}

.see .overlap-3 {
  background-image: url(../../../public/img/login/overlap-3.svg);
  background-size: 100% 100%;
  height: 88px;
  left: 0px;
  position: absolute;
  top: 130px;
  width: 600px;
  border-radius: 44px;
}

.see .text-wrapper-5 {
  color: #f2f2f2;

  font-size: var(--font-14-22-regular-font-size);
  font-style: var(--font-14-22-regular-font-style);
  font-weight: var(--font-14-22-regular-font-weight);
  left: 280px;
  letter-spacing: var(--font-14-22-regular-letter-spacing);
  line-height: var(--font-14-22-regular-line-height);
  position: absolute;
  text-align: center;
  top: 22px;
  white-space: nowrap;
}

.see .rectangle {
  background-color: var(--cmaskbg);
  height: 1688px;
  left: 0;
  position: absolute;
  top: 0;
  width: 780px;
}

.see .overlap-wrapper-2 {
  height: 300px;
  left: 110px;
  position: absolute;
  top: 694px;
  width: 568px;
}

.see .overlap-4 {
  background-color: #ffffff;
  height: 280px;
  position: relative;
  width: 560px;
  border-radius: 20px;
}

.see .text-wrapper-6 {
  color: var(--ctextwbgnormal-60);

  font-size: var(--font-14-22-regular-font-size);
  font-style: var(--font-14-22-regular-font-style);
  font-weight: var(--font-14-22-regular-font-weight);
  left: 40px;
  letter-spacing: var(--font-14-22-regular-letter-spacing);
  line-height: var(--font-14-22-regular-line-height);
  position: absolute;
  text-align: center;
  top: 50px;
  width: 480px;
}

.see .image-2 {
  height: 2px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 182px;
  width: 560px;
}

.see .text-wrapper-7 {
  color: #5A7CAB;

  font-size: var(--font-16-24-regular-font-size);
  font-style: var(--font-16-24-regular-font-style);
  font-weight: var(--font-16-24-regular-font-weight);
  width:560px;
  letter-spacing: var(--font-16-24-regular-letter-spacing);
  line-height: var(--font-16-24-regular-line-height);
  position: absolute;
  text-align: center;
  top: 202px;
  white-space: nowrap;
}

.see .err {
  position: absolute;
  top: 64px;
  font-size: 20px;
  left: 40px;
  color: #c51d1d;
}

.see .phone-err {
  position: absolute;
  top: 174px;
  font-size: 20px;
  left: 80px;
  color: #c51d1d;
}

.see input {
  border: none;
  color: #333;
}

.see .err-code {
  position: absolute;
  font-size: 20px;
  top: 70px;
  color: #c51d1d;
  right: 32px;
}

.see .err-login {
  position: absolute;
  top: -50px;
  font-size: 25px;
  /* left: 40px; */
  color: #e58383;
  width: 600px;
  text-align: center;
}

.see .logo{
  position: absolute;
  top: 100px;
  width: 200px;
  left: 280px;
}
.see .phone{
  position: absolute;
  top: 65px;
  width: 420px;
  left: 70px;
  padding: 15px 30px;
  font-size: 25px;
  border: 1px solid;
  border-radius: 100px;
  text-align: center;
}

.see .tableItem .kuang{
  width: 700px;
  margin: 40px;
  margin-top: 75px;
  margin-bottom: 55px;
  border-radius: 40px;
  text-align: center;
  color: black;
  background-color: white;

}

.see .tableItem img{
  width: 700px;
  height: 500px;
}

.see .tableItem .button {
  background-image: url(../../../public/img/login/overlap-3.svg);
  background-size: 100% 100%;
  height: 88px;
  width: 600px;
  border-radius: 44px;
  color: #f2f2f2;
  font-size: 30px;
  margin-left: 80px;
  font-family: 'LiciumFont2022-Regular' !important;
}
.see .tableItem img {
  width: 699px;
  -moz-border-radius-bottomleft: 40px;
  -webkit-border-bottom-left-radius: 40px;
  -moz-border-radius-bottomright: 40px;
  -webkit-border-bottom-right-radius: 40px;
  margin-top:10px;
}
.see .tableItem .site {
  margin-top: 75px;
  font-size: 32px;
}
.see .tableItem .number {
  margin: 0 auto;
  margin-top: 20px;
  font-size: 45px;
  font-weight: 700;
  border-bottom: 1px solid;
  width: 350px;
}
.see a{
  text-decoration: none;
  color: #f2f2f2;
}
