.recommend {
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .recommend .div {
    
    border: 2px none;
    overflow: hidden;
    position: relative;
    width: 780px;
    padding-bottom:105px;
  }
  .overlap-bottom-text{
    position: relative;
    margin-bottom: 40px;
    background-color: #f8f8f8;
    height: 200px;
  }
  
  .recommend .overlap-group-wrapper {
    height: 88px;
    left: 0;
    position: absolute;
    top: 88px;
    width: 784px;
  }
  
  .recommend .overlap-group {
    background-color: #ffffff;
    height: 88px;
    position: relative;
    width: 780px;
  }
  
  .recommend .icon-fanhui {
    height: 56px !important;
    left: 32px !important;
    position: absolute !important;
    top: 16px !important;
    width: 56px !important;
  }
  
  .recommend .text-wrapper {
    color: var(--ctextwbgprimary-90);
    
    font-size: var(--font-16-24-medium-font-size);
    font-style: var(--font-16-24-medium-font-style);
    font-weight: var(--font-16-24-medium-font-weight);
    left: 184px;
    letter-spacing: var(--font-16-24-medium-letter-spacing);
    line-height: var(--font-16-24-medium-line-height);
    position: absolute;
    text-align: center;
    top: 20px;
    white-space: nowrap;
  }
  
  .recommend .view {
    height: 56px;
    left: 692px;
    position: absolute;
    top: 16px;
    width: 56px;
  }
  
  .recommend .div-2 {
    background-color: #191919;
    border-radius: 3px;
    height: 6px;
    left: 24px;
    position: absolute;
    top: 24px;
    width: 6px;
  }
  
  .recommend .div-3 {
    background-color: #191919;
    border-radius: 3px;
    height: 6px;
    left: 12px;
    position: absolute;
    top: 24px;
    width: 6px;
  }
  
  .recommend .div-4 {
    background-color: #191919;
    border-radius: 3px;
    height: 6px;
    left: 40px;
    position: absolute;
    top: 24px;
    width: 6px;
  }
  
  .recommend .iphone-x-status-bars {
    background-color: #ffffff;
    height: 88px;
    left: 0;
    position: absolute;
    top: 0;
    width: 780px;
  }
  
  .recommend .battery {
    height: 24px;
    left: 694px;
    position: absolute;
    top: 36px;
    width: 48px;
  }
  
  .recommend .wifi {
    height: 24px;
    left: 650px;
    position: absolute;
    top: 36px;
    width: 32px;
  }
  
  .recommend .cellular-connection {
    height: 22px;
    left: 604px;
    position: absolute;
    top: 38px;
    width: 32px;
  }
  
  .recommend .time-style {
    height: 36px;
    left: 42px;
    overflow: hidden;
    position: absolute;
    top: 30px;
    width: 108px;
  }
  
  .recommend .time {
    color: #000000;
    
    font-size: 32px;
    font-weight: 400;
    left: 20px;
    letter-spacing: -1.2px;
    line-height: normal;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .recommend .group {
    height: 68px;
    left: 0;
    position: absolute;
    top: 1908px;
    width: 780px;
  }
  
  .recommend .home-indicator-light {
    height: 68px;
    overflow: hidden;
  }
  
  .recommend .home-indicator-wrapper {
    background-color: #ffffff;
    height: 72px;
    left: -2px;
    position: relative;
    top: -2px;
    width: 784px;
  }
  
  .recommend .home-indicator {
    background-color: #000000;
    border-radius: 5px;
    height: 8px;
    left: 252px;
    position: relative;
    top: 42px;
    width: 280px;
  }
  
  .recommend .overlap {
    background-color: #ffffff;
    height: 1456px;
    left: 0;
    position: relative;
    width: 780px;
  }
  
  .recommend .mask-group {
    height: 586px;
    left: 0;
    position: absolute;
    top: 0;
    width: 780px;
  }
  
  .recommend .text-wrapper-2 {
    color: var(--ctextwbgprimary-90);
    
    font-size: var(--font-20-28-medium-font-size);
    font-style: var(--font-20-28-medium-font-style);
    font-weight: var(--font-20-28-medium-font-weight);
    left: 40px;
    letter-spacing: var(--font-20-28-medium-letter-spacing);
    line-height: var(--font-20-28-medium-line-height);
    position: absolute;
    top: 624px;
    white-space: nowrap;
  }
  
  .recommend .text-wrapper-3 {
    color: var(--ctextwbgnormal-60);
    
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    left: 88px;
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    position: absolute;
    text-align: justify;
    top: 704px;
    white-space: normal;
    text-align: left;
  }
  
  .recommend .element-px {
    height: 40px;
    left: 40px;
    position: absolute;
    top: 708px;
    width: 40px;
  }
  
  .recommend .overlap-2 {
    height: 32px;
    left: 4px;
    position: relative;
    top: 3px;
    width: 32px;
  }
  
  .recommend .subtract-wrapper {
    height: 32px;
    left: 0;
    position: absolute;
    top: 0;
    width: 32px;
  }
  
  .recommend .subtract {
    height: 28px;
    left: 4px;
    position: absolute;
    top: 2px;
    width: 24px;
  }
  
  .recommend .ellipse {
    background-color: #ffffff;
    border: 2px solid;
    border-color: #999999;
    border-radius: 5.2px;
    height: 9px;
    left: 11.5px;
    position: absolute;
    top: 10px;
    width: 9px;
  }
  
  .recommend .group-2 {
    height: 126px;
    left: 40px;
    position: absolute;
    top: 1102px;
    width: 504px;
  }
  
  .recommend .text-wrapper-4 {
    color: var(--ctextwbgprimary-90);
    
    font-size: var(--font-16-24-medium-font-size);
    font-style: var(--font-16-24-medium-font-style);
    font-weight: var(--font-16-24-medium-font-weight);
    left: 0;
    letter-spacing: var(--font-16-24-medium-letter-spacing);
    line-height: var(--font-16-24-medium-line-height);
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .recommend .group-3 {
    height: 44px;
    left: 0;
    position: absolute;
    top: 60px;
    width: 228px;
  }
  
  .recommend .text-wrapper-5 {
    color: var(--ctextlink);
    
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    left: 52px;
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    position: absolute;
    text-align: justify;
    top: 0;
    white-space: nowrap;
  }

.recommend .text-wrapper-5 a{
  text-decoration: none;
  color:  #5A7CAB;
  text-align: justify;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
}
  .recommend .vector-wrapper {
    height: 44px;
    left: 0;
    position: absolute;
    top: 0;
    width: 44px;
  }
  
  .recommend .vector {
    height: 30px;
    left: 8px;
    position: absolute;
    top: 8px;
    width: 30px;
  }
  
  .recommend .group-4 {
    height: 44px;
    left: 264px;
    position: absolute;
    top: 80px;
    width: 240px;
  }
  
  .recommend .image {
    height: 2px;
    left: 40px;
    object-fit: cover;
    position: absolute;
    top: 790px;
    width: 700px;
  }
  .recommend .image2 {
    height: 2px;
    left: 40px;
    object-fit: cover;
    position: absolute;
    top: 1400px;
    width: 700px;
  }
  
  .recommend .group-5 {
    height: 184px;
    left: 40px;
    position: absolute;
    top: 854px;
    width: 720px;
  }
  
  .recommend .text-wrapper-6 {
    color: var(--ctextwbgprimary-90);
    
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    left: 0;
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    position: absolute;
    text-align: justify;
    top: 80px;
    white-space: nowrap;
  }
  
  .recommend .text-wrapper-7 {
    color: var(--ctextwbgprimary-90);
    
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    right: 20px;
    
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    position: absolute;
    text-align: justify;
    top: 80px;
    white-space: nowrap;
  }
  
  .recommend .text-wrapper-8 {
    color: var(--ctextwbgprimary-90);
    
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    right: 20px;
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    position: absolute;
    text-align: justify;
    top: 140px;
    white-space: nowrap;
  }
  
  .recommend .text-wrapper-9 {
    color: var(--ctextwbgprimary-90);
    
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    left: 0;
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    position: absolute;
    text-align: justify;
    top: 140px;
    white-space: nowrap;
  }
  
  .recommend .text-wrapper-10 {
    color: var(--ctextwbgnormal-60);
    
    font-size: var(--font-12-20-regular-font-size);
    font-style: var(--font-12-20-regular-font-style);
    font-weight: var(--font-12-20-regular-font-weight);
    left: 40px;
    letter-spacing: var(--font-12-20-regular-letter-spacing);
    line-height: var(--font-12-20-regular-line-height);
    position: absolute;
    text-align: justify;
    top: 20px;
    white-space: nowrap;
    margin-bottom: 10px;
  }
  
  .recommend .text-wrapper-11 {
    color: var(--ctextwbgnormal-60);
    
    font-size: var(--font-12-20-regular-font-size);
    font-style: var(--font-12-20-regular-font-style);
    font-weight: var(--font-12-20-regular-font-weight);
    left: 40px;
    letter-spacing: -1px;
    line-height: var(--font-12-20-regular-line-height);
    position: absolute;
    text-align: justify;
    top: 60px;
    width: 700px;
  }
  
  .recommend .overlap-wrapper {
    height: 98px;
    left: 0;
    position: absolute;
    top: 1810px;
    width: 800px;
  }
  
  .recommend .overlap-3 {
    background-color: #ffffff;
    height: 102px;
    left: -2px;
    position: relative;
    top: -2px;
    width: 784px;
  }
  
  .recommend .overlap-4 {
    height: 88px;
    left: 508px;
    position: absolute;
    top: 6px;
    width: 82px;
  }
  
  .recommend .view-2 {
    height: 56px;
    left: 14px;
    position: absolute;
    top: 0;
    width: 56px;
  }
  
  .recommend .overlap-group-2 {
    height: 36px;
    left: 10px;
    position: relative;
    top: 12px;
    width: 40px;
  }
  
  .recommend .rectangle {
    height: 4px;
    left: 0;
    position: absolute;
    top: 32px;
    width: 40px;
  }
  
  .recommend .img {
    height: 36px;
    left: 2px;
    position: absolute;
    top: 0;
    width: 36px;
  }
  
  .recommend .text-wrapper-12 {
    color: #000000e6;
    
    font-size: 20px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 36px;
    opacity: 0.9;
    position: absolute;
    text-align: center;
    top: 52px;
    white-space: nowrap;
  }
  
  .recommend .overlap-5 {
    height: 88px;
    left: 350px;
    position: absolute;
    top: 6px;
    width: 82px;
  }
  
  .recommend .overlap-6 {
    height: 88px;
    left: 38px;
    position: absolute;
    top: 6px;
    width: 82px;
  }
  
  .recommend .overlap-7 {
    height: 88px;
    left: 194px;
    position: absolute;
    top: 6px;
    width: 82px;
  }
  
  .recommend .overlap-8 {
    height: 36px;
    left: 8px;
    position: relative;
    top: 10px;
    width: 40px;
  }
  
  .recommend .rectangle-2 {
    height: 32px;
    left: 2px;
    position: absolute;
    top: 0;
    width: 36px;
  }
  
  .recommend .vector-2 {
    height: 4px;
    left: 10px;
    position: absolute;
    top: 8px;
    width: 20px;
  }
  
  .recommend .vector-3 {
    height: 4px;
    left: 0;
    position: absolute;
    top: 0;
    width: 40px;
  }
  
  .recommend .vector-4 {
    height: 8px;
    left: 18px;
    position: absolute;
    top: 28px;
    width: 4px;
  }
  
  .recommend .vector-5 {
    height: 4px;
    left: 10px;
    position: absolute;
    top: 16px;
    width: 8px;
  }
  
  .recommend .overlap-9 {
    height: 88px;
    left: 664px;
    position: absolute;
    top: 6px;
    width: 82px;
  }
  
  .recommend .img-2 {
    height: 32px;
    left: 8px;
    position: absolute;
    top: 12px;
    width: 40px;
  }
  
  .recommend .img-3 {
    height: 2446px;
    left: -9750px;
    position: absolute;
    top: 6000px;
    width: 780px;
  }
  
  .recommend .img-4 {
    height: 1688px;
    left: -8008px;
    position: absolute;
    top: 6000px;
    width: 780px;
  }
  
  .recommend .img-5 {
    height: 1688px;
    left: -7140px;
    position: absolute;
    top: 6000px;
    width: 780px;
  }
  
  .recommend .img-6 {
    height: 4236px;
    left: -8882px;
    position: absolute;
    top: 6000px;
    width: 780px;
  }

  .recommend .zhushi {
    display: flex;
    justify-content: left;
  }
  