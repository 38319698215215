.information {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.information .div {
    background-color: var(--cbgwhite);
    border: 2px none;
    height: 3300px;
    overflow: hidden;
    position: relative;
    width: 780px;
    top: -176px;
}

.information .overlap-group-wrapper {
    height: 88px;
    left: 0;
    position: absolute;
    top: 88px;
    width: 784px;
}

.information .overlap-group {
    background-color: #ffffff;
    height: 88px;
    position: relative;
    width: 780px;
}

.information .icon-fanhui {
    height: 56px !important;
    left: 32px !important;
    position: absolute !important;
    top: 16px !important;
    width: 56px !important;
}

.information .text-wrapper {
    color: var(--ctextwbgprimary-90);
    
    font-size: var(--font-16-24-medium-font-size);
    font-style: var(--font-16-24-medium-font-style);
    font-weight: var(--font-16-24-medium-font-weight);
    left: 184px;
    letter-spacing: var(--font-16-24-medium-letter-spacing);
    line-height: var(--font-16-24-medium-line-height);
    position: absolute;
    text-align: center;
    top: 20px;
    white-space: nowrap;
}

.information .view {
    height: 56px;
    left: 692px;
    position: absolute;
    top: 16px;
    width: 56px;
}

.information .div-2 {
    background-color: #191919;
    border-radius: 3px;
    height: 6px;
    left: 24px;
    position: absolute;
    top: 24px;
    width: 6px;
}

.information .div-3 {
    background-color: #191919;
    border-radius: 3px;
    height: 6px;
    left: 12px;
    position: absolute;
    top: 24px;
    width: 6px;
}

.information .div-4 {
    background-color: #191919;
    border-radius: 3px;
    height: 6px;
    left: 40px;
    position: absolute;
    top: 24px;
    width: 6px;
}

.information .iphone-x-status-bars {
    background-color: #ffffff;
    height: 88px;
    left: 0;
    position: absolute;
    top: 0;
    width: 780px;
}

.information .battery {
    height: 24px;
    left: 694px;
    position: absolute;
    top: 36px;
    width: 48px;
}

.information .wifi {
    height: 24px;
    left: 650px;
    position: absolute;
    top: 36px;
    width: 32px;
}

.information .cellular-connection {
    height: 22px;
    left: 604px;
    position: absolute;
    top: 38px;
    width: 32px;
}

.information .time-style {
    height: 36px;
    left: 42px;
    overflow: hidden;
    position: absolute;
    top: 30px;
    width: 108px;
}

.information .time {
    color: #000000;
    
    font-size: 32px;
    font-weight: 400;
    left: 20px;
    letter-spacing: -1..2px;
    line-height: normal;
    position: absolute;
    top: 0;
    white-space: nowrap;
}

.information .group {
    height: 68px;
    left: 0;
    position: absolute;
    top: 4168px;
    width: 780px;
}

.information .home-indicator-light {
    height: 68px;
    overflow: hidden;
}

.information .home-indicator-wrapper {
    background-color: #ffffff;
    height: 72px;
    left: -2px;
    position: relative;
    top: -2px;
    width: 784px;
}

.information .home-indicator {
    background-color: #000000;
    border-radius: 5px;
    height: 14px;
    left: 252px;
    position: relative;
    top: 42px;
    width: 280px;
}

.information .text-wrapper-2 {
    color: var(--ctextwbgprimary-90);
    
    font-size: var(--font-16-24-medium-font-size);
    font-style: var(--font-16-24-medium-font-style);
    font-weight: var(--font-16-24-medium-font-weight);
    left: 0px;
    letter-spacing: var(--font-16-24-medium-letter-spacing);
    line-height: var(--font-16-24-medium-line-height);
    position: absolute;
    top: -140px;
    white-space: nowrap;
}

.information .group-2 {
    height: 1988px;
    left: 40px;
    position: absolute;
    top: 1384px;
    width: 704px;
}

.information .text-wrapper-3 {
    color: var(--ctextwbgprimary-90);
    
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    left: 0;
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    position: absolute;
    top: -60px;
    white-space: nowrap;
}

.information .group-wrapper {
    height: 340px;
    left: 0;
    position: absolute;
    top: 60px;
    width: 700px;
}

.information .overlap-wrapper {
    height: 340px;
    width: 704px;
}

.information .overlap {
    background-color: #f8f8f8;
    border-radius: 16px;
    height: 340px;
    position: relative;
    width: 700px;
}

.information .group-3 {
    height: 64px;
    left: 32px;
    position: absolute;
    top: 244px;
    width: 636px;
}

.information .div-wrapper {
    background-image: url(../../../public/img/img2/10.svg);
    background-size: 100% 100%;
    height: 64px;
    left: 0;
    position: absolute;
    top: 0;
    width: 310px;
}

.information .group-4 {
    height: 44px;
    left: 90px;
    position: relative;
    top: 10px;
    width: 134px;
}

.information .icon-20-xiajiantou {
    left: 90px !important;
    position: absolute !important;
    top: 2px !important;
}

.information .text-wrapper-4 {
    color: var(--ctextthemegreen);
    
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    left: 0;
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    position: absolute;
    top: 0;
    white-space: nowrap;
}

.information .overlap-group-wrapper-2 {
    height: 64px;
    left: 326px;
    position: absolute;
    top: 0;
    width: 314px;
}

.information .overlap-group-2 {
    background-image: url(../../../public/img/img2/9.svg);
    background-size: 100% 100%;
    height: 64px;
    position: relative;
    width: 310px;
}

.information .text-wrapper-5 {
    color: var(--ctextwbgnormal-60);
    
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    left: 112px;
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    position: absolute;
    top: 10px;
    white-space: nowrap;
}

.information .group-5 {
    height: 100px;
    left: 32px;
    position: absolute;
    top: 112px;
    width: 368px;
}

.information .text-wrapper-6 {
    color: var(--ctextwbgprimary-90);
    
    font-size: var(--font-16-24-medium-font-size);
    font-style: var(--font-16-24-medium-font-style);
    font-weight: var(--font-16-24-medium-font-weight);
    left: 0;
    letter-spacing: var(--font-16-24-medium-letter-spacing);
    line-height: var(--font-16-24-medium-line-height);
    position: absolute;
    top: 0;
    white-space: nowrap;
}

.information .text-wrapper-7 {
    color: var(--ctextwbgprimary-90);
    
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    left: 0;
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    position: absolute;
    top: 56px;
    white-space: nowrap;
}

.information .text-wrapper-8 {
    color: var(--ctextwbgsecondary-40);
    
    font-size: var(--font-16-24-regular-font-size);
    font-style: var(--font-16-24-regular-font-style);
    font-weight: var(--font-16-24-regular-font-weight);
    left: 32px;
    letter-spacing: var(--font-16-24-regular-letter-spacing);
    line-height: var(--font-16-24-regular-line-height);
    position: absolute;
    top: 32px;
    white-space: nowrap;
}

.information .group-6 {
    height: 340px;
    left: 0;
    position: absolute;
    top: 875px;
    width: 700px;
}

.information .overlap-group-wrapper-3 {
    height: 64px;
    left: 0;
    position: absolute;
    top: 0;
    width: 314px;
}

.information .text-wrapper-9 {

    
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    left: 126px;
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    position: absolute;
    top: 10px;
    white-space: nowrap;
}

.information .overlap-group-3 {
    background-image: url(../../../public/img/img2/10.svg);
    background-size: 100% 100%;
    height: 64px;
    position: relative;
    width: 310px;
}

.information .text-wrapper-10 {

    
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    left: 112px;
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    position: absolute;
    top: 10px;
    white-space: nowrap;
}

.information .group-7 {
    height: 100px;
    left: 32px;
    position: absolute;
    top: 112px;
    width: 644px;
}

.information .text-wrapper-11 {
    color: var(--ctextwbgprimary-90);
    
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    left: 0;
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    position: absolute;
    top: 56px;
    width: 636px;
}

.information .group-8 {
    height: 340px;
    left: 0;
    position: absolute;
    top: 1230px;
    width: 700px;
}

.information .group-9 {
    height: 44px;
    left: 126px;
    position: relative;
    top: 10px;
    width: 62px;
}

.information .group-10 {
    height: 340px;
    left: 0;
    position: absolute;
    top: 260px;
    width: 700px;
}

.information .group-11 {
    height: 340px;
    width: 712px;
}

.information .text-wrapper-12 {
    color: var(--ctextthemegreen);
    
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    left: 126px;
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    position: absolute;
    top: 10px;
    white-space: nowrap;
}

.information .text-wrapper-13 {
    color: var(--ctextwbgprimary-90);
    
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    left: 32px;
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    position: absolute;
    top: 168px;
    white-space: nowrap;
}

.information .text-wrapper-14 {
    color: var(--ctextwbgprimary-90);
    
    font-size: var(--font-16-24-medium-font-size);
    font-style: var(--font-16-24-medium-font-style);
    font-weight: var(--font-16-24-medium-font-weight);
    left: 32px;
    letter-spacing: var(--font-16-24-medium-letter-spacing);
    line-height: var(--font-16-24-medium-line-height);
    position: absolute;
    top: 112px;
    white-space: nowrap;
}

.information .group-12 {
    height: 244px;
    left: 0;
    position: absolute;
    top: 615px;
    width: 700px;
}

.information .group-13 {
    height: 244px;
    width: 712px;
}

.information .overlap-group-4 {
    background-color: #f8f8f8;
    border-radius: 16px;
    height: 244px;
    position: relative;
    width: 700px;
}

.information .group-14 {
    height: 244px;
    left: 0;
    position: absolute;
    top: 0px;
    width: 700px;
}

.information .group-15 {
    height: 400px;
    left: 40px;
    position: absolute;
    top: 3370px;
    width: 704px;
}

.information .group-16 {
    height: 340px;
    left: 0;
    position: absolute;
    top: 60px;
    width: 704px;
}

.information .group-wrapper-2 {
    background-image: url(../../../public/img/img2/9.svg);
    background-size: 100% 100%;
    height: 64px;
    left: 0;
    position: absolute;
    top: 0;
    width: 310px;
}

.information .group-17 {
    height: 44px;
    left: 102px;
    position: relative;
    top: 10px;
    width: 110px;
}

.information .icon-xiajiantou-instance {
    left: 66px !important;
    position: absolute !important;
    top: 2px !important;
}

.information .text-wrapper-15 {
    color: var(--ctextwbgnormal-60);
    
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    left: 0;
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    position: absolute;
    top: 0;
    white-space: nowrap;
}

.information .group-18 {
    height: 1014px;
    left: 0;
    position: absolute;
    top: 176px;
    width: 788px;
}

.information .mask-group {
    height: 586px;
    width: 780px;
}

.information .text-wrapper-16 {
    color: var(--ctextwbgprimary-90);
    
    font-size: var(--font-20-28-medium-font-size);
    font-style: var(--font-20-28-medium-font-style);
    font-weight: var(--font-20-28-medium-font-weight);
    letter-spacing: var(--font-20-28-medium-letter-spacing);
    line-height: var(--font-20-28-medium-line-height);
    /* position: absolute;
    top: 626px; */
    margin-top: 50px;
    white-space: nowrap;
    font-size: 36px;
    margin-left: 40px;
}

.information .group-19 {
    height: 88px;
    left: 40px;
    position: absolute;
    top: 758px;
    width: 704px;
}

.information .text-wrapper-17 {
    color: var(--ctextwbgnormal-60);
    
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    left: 48px;
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    position: absolute;
    text-align: justify;
    top: 0;
    width: 652px;
    text-align: left;
}

.information .overlap-group-wrapper-4 {
    height: 40px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 40px;
}

.information .overlap-group-5 {
    height: 32px;
    left: 4px;
    position: relative;
    top: 4px;
    width: 32px;
}

.information .subtract-wrapper {
    height: 32px;
    left: 0;
    position: absolute;
    top: 0;
    width: 32px;
}

.information .subtract {
    height: 28px;
    left: 4px;
    position: absolute;
    top: 2px;
    width: 24px;
}

.information .ellipse {
    background-color: #ffffff;
    border: 2.4px solid;
    border-color: #999999;
    border-radius: 5.2px;
    height: 9px;
    left: 11.5px;
    position: absolute;
    top: 10px;
    width: 9px;
}

.information .text-wrapper-18 {
    color: var(--ctextwbgsecondary-40);
    
    font-size: var(--font-12-20-regular-font-size);
    font-style: var(--font-12-20-regular-font-style);
    font-weight: var(--font-12-20-regular-font-weight);
    left: 40px;
    letter-spacing: var(--font-12-20-regular-letter-spacing);
    line-height: var(--font-12-20-regular-line-height);
    position: absolute;
    text-align: justify;
    top: 910px;
    width: 700px;
    text-align: left;
}

.information .image {
    height: 2px;
    left: 40px;
    object-fit: cover;
    position: absolute;
    top: 1012px;
    width: 700px;
}

.information .img {
    height: 2px;
    left: 40px;
    object-fit: cover;
    position: absolute;
    top: 884px;
    width: 700px;
}

.information .group-20 {
    height: 44px;
    left: 40px;
    position: absolute;
    top: 706px;
    width: 704px;
}

.information .overlap-group-6 {
    height: 28px;
    left: 10px;
    position: relative;
    top: 6px;
    width: 22px;
}

.information .vector {
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 22px;
}

.information .vector-2 {
    height: 28px;
    left: 1px;
    position: absolute;
    top: 0px;
    width: 3px;
}

.information .overlap-wrapper-2 {
    height: 98px;
    left: 0;
    position: absolute;
    top: 4070px;

}

.information .overlap-2 {
    background-color: #ffffff;
    height: 102px;
    left: -2px;
    position: relative;
    top: -2px;
    width: 784px;
}

.information .overlap-3 {
    height: 88px;
    left: 508px;
    position: absolute;
    top: 6px;
    width: 82px;
}

.information .view-2 {
    height: 56px;
    left: 14px;
    position: absolute;
    top: 0;
    width: 56px;
}

.information .overlap-group-7 {
    height: 36px;
    left: 10px;
    position: relative;
    top: 12px;
    width: 40px;
}

.information .rectangle {
    height: 4px;
    left: 0;
    position: absolute;
    top: 32px;
    width: 40px;
}

.information .group-21 {
    height: 36px;
    left: 2px;
    position: absolute;
    top: 0;
    width: 36px;
}

.information .text-wrapper-19 {
    color: #000000e6;
    
    font-size: 20px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 36px;
    opacity: 0.9;
    position: absolute;
    text-align: center;
    top: 52px;
    white-space: nowrap;
}

.information .overlap-4 {
    height: 88px;
    left: 350px;
    position: absolute;
    top: 6px;
    width: 82px;
}

.information .overlap-5 {
    height: 88px;
    left: 38px;
    position: absolute;
    top: 6px;
    width: 82px;
}

.information .overlap-6 {
    height: 88px;
    left: 194px;
    position: absolute;
    top: 6px;
    width: 82px;
}

.information .overlap-7 {
    height: 36px;
    left: 8px;
    position: relative;
    top: 10px;
    width: 40px;
}

.information .rectangle-2 {
    height: 32px;
    left: 2px;
    position: absolute;
    top: 0;
    width: 36px;
}

.information .vector-3 {
    height: 4px;
    left: 10px;
    position: absolute;
    top: 8px;
    width: 20px;
}

.information .vector-4 {
    height: 4px;
    left: 0;
    position: absolute;
    top: 0;
    width: 40px;
}

.information .vector-5 {
    height: 8px;
    left: 18px;
    position: absolute;
    top: 28px;
    width: 4px;
}

.information .vector-6 {
    height: 4px;
    left: 10px;
    position: absolute;
    top: 16px;
    width: 8px;
}

.information .overlap-8 {
    height: 88px;
    left: 664px;
    position: absolute;
    top: 6px;
    width: 82px;
}

.information .img-2 {
    height: 32px;
    left: 8px;
    position: absolute;
    top: 12px;
    width: 40px;
}

.text-wrapper-kong {
    color: var(--ctextwbgnormal-60) !important;
}

.information .text-wrapper-20 {

    color: #000000e6;
    
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    left: 210px;
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    position: absolute;
    top: 3832px;
    white-space: nowrap;
}

.information .view-3 {
    height: 88px;
    left: 40px;
    position: absolute;
    top: 3030px;
    width: 704px;
}

.information .overlap-9 {
    background-image: url(../../../public/img/img2/btn.svg);
    background-size: 100% 100%;
    height: 88px;
    position: relative;
    width: 700px;
}

.information .text-wrapper-21 {
    color: var(--ctextbbgprimary-100);
    
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    left: 320px;
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    position: absolute;
    text-align: center;
    top: 22px;
    white-space: nowrap;
}

.information .xuanzhong {
    color: rgba(5, 117, 104, 1) !important;
    background-image: url(../../../public/img/img2/10.svg);
}

.information .weixuan {
    color: rgba(0, 0, 0, 0.6) !important;
    background-image: url(../../../public/img/img2/9.svg);
}


.information-view-3 {
    height: 648px;

    position: fixed;
    bottom: 0;
    /* background: #fff; */
    z-index: 999;
}

.information-overlap-10 {
    background-image: url(../../../public/img/img2/11.svg);
    background-size: 100% 100%;
    height: 648px;
    position: relative;
    width: 780px;
}

.information-text-wrapper-19 {
    color: var(--ctextwbgprimary-90);
    
    font-size: var(--font-16-24-regular-font-size);
    font-style: var(--font-16-24-regular-font-style);
    font-weight: var(--font-16-24-regular-font-weight);
    left: 40px;
    letter-spacing: var(--font-16-24-regular-letter-spacing);
    line-height: var(--font-16-24-regular-line-height);
    position: absolute;
    top: 112px;
    white-space: nowrap;
    width: 700px;
}

.information-vector-7 {
    height: 2px;
    left: 40px;
    object-fit: cover;
    position: absolute;
    top: 192px;
    width: 740px;
}

.information-vector-7 {
    height: 2px;
    left: 40px;
    object-fit: cover;
    position: absolute;
    top: 192px;
    width: 740px;
}

.information-vector-8 {
    height: 2px;
    left: 40px;
    object-fit: cover;
    position: absolute;
    top: 304px;
    width: 740px;
}

.information-vector-9 {
    height: 2px;
    left: 40px;
    object-fit: cover;
    position: absolute;
    top: 416px;
    width: 740px;
}

.information-vector-10 {
    height: 2px;
    left: 40px;
    object-fit: cover;
    position: absolute;
    top: 528px;
    width: 740px;
}

.information-text-wrapper-20 {
    color: #000000e6;
    
    font-size: var(--16-24-regular-font-size);
    font-style: var(--16-24-regular-font-style);
    font-weight: var(--16-24-regular-font-weight);
    left: 40px;
    letter-spacing: var(--16-24-regular-letter-spacing);
    line-height: var(--16-24-regular-line-height);
    position: absolute;
    top: 224px;
    white-space: nowrap;
    width: 700px;

}

.information-text-wrapper-21 {
    color: var(--ctextwbgprimary-90);
    
    font-size: var(--font-16-24-regular-font-size);
    font-style: var(--font-16-24-regular-font-style);
    font-weight: var(--font-16-24-regular-font-weight);
    left: 40px;
    letter-spacing: var(--font-16-24-regular-letter-spacing);
    line-height: var(--font-16-24-regular-line-height);
    position: absolute;
    top: 336px;
    white-space: nowrap;
    width: 700px;

}

.information-text-wrapper-22 {
    color: var(--ctextwbgprimary-90);
    
    font-size: var(--font-16-24-regular-font-size);
    font-style: var(--font-16-24-regular-font-style);
    font-weight: var(--font-16-24-regular-font-weight);
    left: 40px;
    letter-spacing: var(--font-16-24-regular-letter-spacing);
    line-height: var(--font-16-24-regular-line-height);
    position: absolute;
    top: 448px;
    white-space: nowrap;
    width: 700px;
}

.information-text-wrapper-23 {
    color: var(--ctextwbgprimary-90);
    
    font-size: var(--font-16-24-medium-font-size);
    font-style: var(--font-16-24-medium-font-style);
    font-weight: var(--font-16-24-medium-font-weight);
    left: 324px;
    letter-spacing: var(--font-16-24-medium-letter-spacing);
    line-height: var(--font-16-24-medium-line-height);
    position: absolute;
    text-align: center;
    top: 32px;
    white-space: nowrap;
    width: 100%;

}

.frame {
    align-items: center;
    background-color: #00000099;
    border: 2px none;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    min-height: 120px;
    min-width: 334px;
    padding: 16px 24px;
    position: fixed;
    top: 100px;
    z-index: 999;
    border-radius: 20px;
    top: 50%;
    left: 50%;
    margin-left: -325px;
    margin-top: -60px;
    width: 650px;
}

.frame2 {
    align-items: center;
    background-color: #00000099;
    border: 2px none;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    min-height: 120px;
    min-width: 334px;
    padding: 16px 24px;
    position: fixed;
    top: 100px;
    z-index: 999;
    border-radius: 20px;
    top: 50%;
    left: 50%;
    margin-left: -325px;
    margin-top: -60px;
    width: 650px;
}

.frame .text-wrapper-frame {
    color: var(--ctextbbgprimary-100);
    
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    margin-top: -2px;
    position: relative;
    text-align: center;
    width: fit-content;


}
.frame2 .text-wrapper-frame {
    color: var(--ctextbbgprimary-100);
    
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    margin-top: -2px;
    position: relative;
    text-align: center;
    width: fit-content;


}

.information .logo{
  position: absolute;
  top: 280px;
  width: 200px;
  left: 280px;
  z-index: 999;
}
.baomi{
    
        align-items: center;
        background-color: #00000099;
        border: 2px none;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        min-height: 120px;
        min-width: 334px;
        
        position: fixed;
        top: 0;
        z-index: 999;
        
        width: 780px;
        height: 100%;
    
}

.baomi-content{
    position: absolute;
    top: 250px;
    z-index: 99999;
    background-color: white;
    width: 700px;
    left: 40px;
    border-radius: 20px;
}

.baomikuang{
    z-index: 99999;
    width: 700px;
    position: relative
}

.baomi-content .xieyi{
    width: 100%;
    margin-top: 50px;
}

.baomi-content .basetu {
    width: 100%;
    
    text-align: center;
}

.baomi-content .basetu img{
    width: 300px;
    height: 300px;
    border-radius: 20px;
    border: 1px solid black;
}

.baomi-content .qingqueren{
    display: flex;
    justify-content: center;
}


.baomi-content .qingqueren-btn{
    width: 600px;
    margin-bottom: 80px;
    margin-top: 40px;

}
.qianming {
    display: flex;
    justify-content: center;
}
.qianming-btn {
    font-size: 25px;
    padding: 10px 50px;
    text-align: center;
    background-color: rgb(249, 242, 233);
    color: #000000;
    border-radius: 100px;
    margin-top: 20px;
}
